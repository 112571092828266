import { QueryFunctionContext, useQuery } from "@tanstack/react-query";

import { ListQueryResponse } from "@smartrent/ui";

import { instance } from "@/react/hooks/api";
import { createAxiosMutation } from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";

import { Role } from "@/react/types/Role";
import { Id } from "@/react/types";

interface ListVacantManagerAccessFilters {
  page?: number;
  limit?: number;
  sort?: string;
  dir?: string;
}

export interface VacantManagerAccess {
  id: number;
  name: string;
  access_granted: boolean;
  account_accepted: boolean;
  employee_role: Role | null;
}

const getVacantManagerAccessUrl = (groupId: any) =>
  `/groups/${groupId}/vacant-manager-access`;

export const FetchVacantManagerAccess = async ({
  queryKey,
}: QueryFunctionContext) => {
  const [, { groupId }, filters] = queryKey as [
    string | readonly unknown[],
    Record<string, unknown>,
    ListVacantManagerAccessFilters
  ];

  const response = await instance().get(getVacantManagerAccessUrl(groupId), {
    params: filters,
  });

  return response.data as ListQueryResponse<VacantManagerAccess>;
};

export interface VacantAccessUserError {
  unit_id: number;
  unit_name: string;
  error: string;
  message: string;
}

export interface VacantAccessUser {
  id: number;
  group_id: number;
  inserted_at: string;
  updated_at: string;
  last_used_at: string;
  user: {
    id: number;
    first_name: string | null;
    last_name: string | null;
  };
  errors?: Array<VacantAccessUserError>;
}

export const fetchVacantAccessList = async ({
  queryKey,
}: QueryFunctionContext) => {
  const [, { groupId }, filters] = queryKey as [
    string | readonly unknown[],
    Record<string, unknown>,
    ListVacantManagerAccessFilters
  ];

  const response = await instance().get(
    `/groups/${groupId}/settings/vacant-access`,
    {
      params: filters,
    }
  );

  return response.data as ListQueryResponse<VacantAccessUser>;
};

export const useUsersVacantAccess = (userId: Id) =>
  useQuery(["vacant_access"], async () => {
    const { data } = await instance().get<ListQueryResponse<VacantAccessUser>>(
      `/users/${userId}/vacant-access`
    );

    return data;
  });

const vacantAccessUserQueryKey = (groupId: Id, vacantAccessCodeId: Id) => [
  "group",
  `${groupId}`,
  "settings/vacant-access",
  `${vacantAccessCodeId}`,
];

export const useVacantAccessUser = (groupId: Id, vacantAccessCodeId: Id) =>
  useQuery(
    vacantAccessUserQueryKey(groupId, vacantAccessCodeId),
    async () => {
      const { data } = await instance().get<VacantAccessUser>(
        `/groups/${groupId}/settings/vacant-access/${vacantAccessCodeId}`
      );

      return data;
    },
    {
      enabled: !!groupId && !!vacantAccessCodeId,
    }
  );

export const useDeleteVacantAccessUser = createAxiosMutation(
  async ({
    groupId,
    vacantAccessUserId,
  }: {
    groupId: Id;
    vacantAccessUserId: Id;
  }) => {
    const { data } = await instance().delete(
      `/groups/${groupId}/settings/vacant-access/${vacantAccessUserId}`
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully deleted Vacant Access User.",
    }),
    errorToast: () => ({
      message: "Unable to delete Vacant Access User.",
    }),
  }
);

export const useActivateVacantManagerAccess = createAxiosMutation(
  async ({
    groupId,
  }: {
    groupId: number;
    queryParams?: Record<string, string | null>;
  }) => {
    const { data } = await instance().post(
      `/groups/${groupId}/activate-vacant-manager-access`
    );
    return data;
  },
  {
    successToast: () => ({
      message: "Vacant staff access enabled.",
    }),
    errorToast: (err) => ({
      message: `Unable to enable vacant staff access. ${getErrorMessage(err)}`,
    }),
    onSuccess: async (queryClient, _result, { groupId, queryParams }) => {
      await queryClient.invalidateQueries([
        "groups",
        { params: { groupId: Number(groupId), queryParams: queryParams } },
      ]);
    },
  }
);

export const useGrantVacantAccess = createAxiosMutation(
  async ({ groupId, userId }: { groupId: Id; userId: Id }) => {
    const { data } = await instance({ version: "v2" }).post(
      `/groups/${groupId}/settings/vacant-access`,
      { user_id: userId }
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Attempting to grant vacant access codes",
    }),
    errorToast: (err) => ({
      message: `Unable to create vacant access codes. ${getErrorMessage(err)}`,
    }),
    onSuccess: async (queryClient, _result, { groupId }) => {
      await queryClient.invalidateQueries([
        "vacant-manager-access",
        { groupId },
      ]);
    },
  }
);

export const useGrantMissingVacantAccess = createAxiosMutation(
  async ({
    groupId,
  }: {
    groupId: number;
    queryParams?: Record<string, string | null>;
  }) => {
    const { data } = await instance().post(
      `/groups/${groupId}/create-missing-vacant-access-codes`
    );
    return data;
  },
  {
    successToast: () => ({
      message: "Attempting to grant missing vacant access codes",
    }),
    errorToast: (err) => ({
      message: `Unable to create missing vacant access codes. ${getErrorMessage(
        err
      )}`,
    }),
    onSuccess: async (queryClient, _result, { groupId, queryParams }) => {
      await queryClient.invalidateQueries([
        "groups",
        { params: { groupId: Number(groupId), queryParams: queryParams } },
      ]);
    },
  }
);

export const useRetryVacantAccessCodes = createAxiosMutation(
  async ({ groupId }: { groupId: Id }) => {
    const { data } = await instance({ version: "v2" }).post(
      `/groups/${groupId}/settings/vacant-access/retry-vacant-access-codes`
    );

    return data;
  },
  {
    successToast: () => ({
      message:
        "Attempting to grant missing access. This can take 5-10 minutes.",
    }),
    errorToast: (err) => {
      if (err?.response?.status === 429) {
        return {
          message: "Vacant access is processing. This can take 5-10 minutes.",
        };
      }
      return {
        message: `Unable to create vacant access codes. ${getErrorMessage(
          err
        )}`,
      };
    },
    onSuccess: async (queryClient, _result, { groupId }) => {
      await queryClient.invalidateQueries([
        "vacant-manager-access",
        { groupId },
      ]);
    },
  }
);
