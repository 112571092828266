import React, { useMemo } from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { useMedia } from "react-use";
import { Button, Menu, Text, useTheme } from "@smartrent/ui";

import { useMenu } from "@smartrent/hooks";

import { ChevronDown } from "@smartrent/icons";

import { NavLink } from "@/react/common/NavLink";
import { NavLinkProps } from "@/react/types";

import { useSidebarWidth } from "@/react/hooks/useIsUnifiedDesignFlagEnabled";

import { RESTRUCTURE_BREAKPOINT } from "./ReactPage";

export const TOP_BAR_HEIGHT = 80;
export const INPUT_BAR_HEIGHT = TOP_BAR_HEIGHT + 32;

interface TopBarProps {
  title?: string | React.ReactNode;
  centered?: boolean;
  navItems?: NavLinkProps[];
  shadow?: boolean;
  border?: boolean;
  hasInputs?: boolean;
  isRelative?: boolean;
  mobileBreakpoint?: string;
  fullWidth?: boolean;
  style?: StyleProp<ViewStyle>;
  navStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<ViewStyle>;
}

export const TopBar: React.FC<TopBarProps> = ({
  title,
  navItems = [],
  children,
  shadow = true,
  border = true,
  hasInputs = false,
  isRelative = false,
  mobileBreakpoint = RESTRUCTURE_BREAKPOINT,
  fullWidth = false,
  centered = false,
  style,
  navStyle,
  titleStyle,
}) => {
  const isMobile = useMedia(mobileBreakpoint);
  const { colors } = useTheme();

  const position = useMemo(() => {
    return isRelative ? "initial" : isMobile ? "absolute" : ("fixed" as any);
  }, [isRelative, isMobile]);

  const height = hasInputs ? INPUT_BAR_HEIGHT : TOP_BAR_HEIGHT;
  const spacerStyle = useMemo(() => ({ height }), [height]);

  let titleElement = null;
  if (typeof title === "string") {
    titleElement = (
      <Text type="title4" style={[centered ? null : styles.title, titleStyle]}>
        {title}
      </Text>
    );
  } else if (title) {
    titleElement = (
      <View style={[centered ? null : styles.title, titleStyle]}>{title}</View>
    );
  }

  const sidebarWidth = useSidebarWidth();

  const visibleNavItems = navItems.filter(
    (navItem) => navItem && navItem?.visible !== false
  );

  const dynamicStyles = {
    position,
    left: isMobile || fullWidth ? 0 : sidebarWidth,
    height,
    backgroundColor: colors.backgroundPrimary,
    borderColor: colors.gray200,
    shadowColor: colors.black,
    borderBottomWidth: border ? 1 : 0,
  };

  return (
    <>
      <View
        style={[
          styles.container,
          shadow ? styles.shadow : null,
          dynamicStyles,
          style,
        ]}
      >
        {titleElement}
        {visibleNavItems.length ? (
          isMobile ? (
            <MobileNav navItems={visibleNavItems} />
          ) : (
            <DesktopNav
              navItems={visibleNavItems}
              fullWidth={fullWidth}
              title={title}
              navStyle={navStyle}
            />
          )
        ) : null}
        {children}
      </View>
      {isRelative ? null : <View style={spacerStyle} />}
    </>
  );
};

type DesktopNavProps = {
  navItems: NavLinkProps[];
  title?: string | React.ReactNode;
  fullWidth?: boolean;
  navStyle?: StyleProp<ViewStyle>;
};

const DesktopNav = ({
  navItems,
  fullWidth,
  title,
  navStyle,
}: DesktopNavProps) => {
  return (
    <View
      style={[
        styles.navSection,
        fullWidth && !title ? styles.navSectionFullWidth : null,
        navStyle,
      ]}
    >
      {navItems.map((item) => (
        <NavLink {...item} type="topNav" key={item.title} />
      ))}
    </View>
  );
};

type MobileNavProps = {
  navItems: NavLinkProps[];
  navStyle?: StyleProp<ViewStyle>;
};

const MobileNav = ({ navItems, navStyle }: MobileNavProps) => {
  const { colors } = useTheme();
  const { buttonProps, menuProps } = useMenu();
  const activeItem = navItems.find((item) => item.isActive);

  if (navItems.length <= 1) {
    return null;
  }

  return (
    <View style={[styles.navSection, styles.navSectionMobile, navStyle]}>
      <Button {...buttonProps} iconRight={ChevronDown} variation="plain">
        {activeItem?.title}
      </Button>
      <Menu
        width={300}
        options={navItems.map((item) => ({
          label: item.title,
          to: item.route,
          color: item.isActive ? colors.primary : colors.black,
        }))}
        {...menuProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    right: 0,
    zIndex: 2,
    minHeight: TOP_BAR_HEIGHT,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 0,
    paddingLeft: 16,
    paddingRight: 8,
  },
  title: {
    marginRight: "auto",
  },
  navSection: {
    marginRight: "auto",
    flexDirection: "row",
  },
  navSectionFullWidth: {
    marginLeft: "auto",
  },
  navSectionMobile: {
    marginRight: 0,
  },
  shadow: {
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
  },
});
