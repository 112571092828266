import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { identity } from "lodash-es";

import { useMutationCompat, useQueryCompat } from "@smartrent/hooks";
import { Typography, useToast } from "@smartrent/ui";

import { useDialog } from "@/react/context/dialog";
import { instance, Options } from "@/react/hooks/api";
import {
  AxiosMutationConfig,
  createAxiosQuery,
} from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";

import {
  Credential,
  IntegrationError,
  Provider,
  ProviderConfiguration,
  ProviderName,
} from "@/react/types/integrations";
import { Option } from "@/react/hooks/useOptions";
import { getProvider } from "@/react/bundles/access_control/configuration/shared";
import { Id } from "@/react/types";

import { settings as settingsLinks } from "@/lib/links";

import { useParkingAccessGroupsQuery } from "../parking-access-groups/parking-access-groups";
import { useInvalidateProviderConfigurationsQuery } from "../provider-configurations";

import { transformEntrataOptions } from "./entrata";
import { transformRealPageOptions } from "./realpage";
import { transformYardiOptions } from "./yardi";

interface UpdateProviderConfigurationOptions {
  groupId: number;
  providerId: number;
  params: any;
  toastOnSuccess?: boolean;
  toastOnError?: boolean;
}

interface ProviderConfigurationSearchParams {
  provider_names?: Array<string>;
  provider_name?: string;
  provider_type?: string;
  provider_id?: string;
  enabled?: boolean;
}

interface ProvidersResponse {
  providers: Provider[];
}

interface ProviderConfigurationResponse {
  configuration: ProviderConfiguration;
  configuration_options: any;
  maelstrom_supported: boolean;
  credential: Credential;
  provider: Provider;
  error?: IntegrationError;
}

interface GetProviderProps {
  groupId: number;
  params?: { type: string };
}

interface ProviderConfigListQueryParams {
  groupId?: Id;
  params?: ProviderConfigurationSearchParams;
}

interface ProviderConfigurationParams {
  groupId: Id;
  slugOrProviderId: Id;
}

interface CardBank {
  label: string;
  value: string;
}

const optionTransformers: { [providerName: string]: any } = {
  [ProviderName.Entrata]: transformEntrataOptions,
  [ProviderName.RealPage]: transformRealPageOptions,
  [ProviderName.Yardi]: transformYardiOptions,
};

export const useProviderConfigurationOptions = (
  groupId: number,
  providerName?: ProviderName,
  configurationOptions?: { [key: string]: any }
): { [key: string]: Option[] } => {
  const { data: parkingAccessGroupsResponse } = useParkingAccessGroupsQuery({
    groupId,
  });
  const transform =
    (providerName && optionTransformers[providerName]) || identity;

  return useMemo(() => {
    if (configurationOptions) {
      const options = transform(configurationOptions);

      // @parking-access-groups
      // if this configuration is for Alloy Access, map the parking access groups
      // to the cmw_groups to be shown in the configuration form.
      if (providerName === ProviderName.AlloyAccess) {
        return {
          ...options,
          cmw_groups: {
            ...options.cmw_groups,
            parking_access_groups:
              parkingAccessGroupsResponse?.records?.map(
                ({ id, group_name }) => ({ id, group_name })
              ) || [],
          },
        };
      }
      return options;
    }

    return {};
  }, [
    configurationOptions,
    transform,
    providerName,
    parkingAccessGroupsResponse,
  ]);
};

export async function getProviders(
  _key: "providers",
  { groupId, params }: GetProviderProps
) {
  const { data } = await instance().get<ProvidersResponse>(
    `/groups/${groupId}/settings/integrations`,
    {
      params,
    }
  );

  return data;
}

export const useProvidersQuery = (groupId: number) =>
  useQueryCompat(["providers", { groupId }], getProviders);

async function getProviderConfiguration(
  _key: "provider-configuration",
  { groupId, slugOrProviderId }: ProviderConfigurationParams
): Promise<ProviderConfigurationResponse> {
  const { data } = await instance().get(
    `/groups/${groupId}/settings/integrations/${slugOrProviderId}`,
    { timeout: 29_000, timeoutErrorMessage: "timeout" }
  );

  return data;
}

export async function getProviderConfigurationList(
  _key: "provider-configuration",
  {
    groupId,
    params,
  }: { groupId: number; params?: ProviderConfigurationSearchParams }
) {
  const { data } = await instance().get<ProviderConfiguration[]>(
    `/groups/${groupId}/settings/integrations/provider-configurations`,
    { params }
  );

  return data;
}

export const useProviderConfigurationListQuery = (
  groupId: number,
  params?: ProviderConfigurationSearchParams
) => {
  return useQueryCompat(
    ["provider-configuration", { groupId, params }],
    getProviderConfigurationList
  );
};

export const useProviderConfigurationListQueryV2 = createAxiosQuery(
  "provider-configurations",
  async ({ groupId, params }: ProviderConfigListQueryParams) => {
    if (groupId) {
      const { data } = await instance().get<ProviderConfiguration[]>(
        `/groups/${groupId}/settings/integrations/provider-configurations`,
        { params }
      );

      return data;
    }

    return null;
  }
);

export const useProviderConfigurationQuery = (
  groupId: number,
  slugOrProviderId: string | number
) => {
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>();

  return useQueryCompat(
    ["provider-configuration", { groupId, slugOrProviderId }],
    getProviderConfiguration,
    {
      refetchInterval,
      onSettled: (data) => {
        if (data?.error === "config_loading") {
          setRefetchInterval(15 * 1000);
        } else {
          setRefetchInterval(undefined);
        }
      },
      enabled: Boolean(groupId) && Boolean(slugOrProviderId),
    }
  );
};

export const useInvalidateProviderConfigurationQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (groupId) =>
      queryClient.invalidateQueries(["provider-configuration", { groupId }]),
    [queryClient]
  );
};

export const useUpdateProviderConfigurationMutation = (
  options?: AxiosMutationConfig<UpdateProviderConfigurationOptions>,
  apiOptions?: Options
) => {
  const setToast = useToast();
  const { confirm } = useDialog();
  const history = useHistory();
  const queryClient = useQueryClient();

  const invalidateProviderConfiguration =
    useInvalidateProviderConfigurationQuery();
  const invalidateProviderConfigurationsQuery =
    useInvalidateProviderConfigurationsQuery();

  return useMutationCompat(
    async ({
      groupId,
      providerId,
      params,
      toastOnSuccess = true,
      toastOnError = true,
    }: UpdateProviderConfigurationOptions) => {
      try {
        const response = await instance(apiOptions).patch(
          `groups/${groupId}/settings/integrations/${providerId}`,
          params
        );

        if (toastOnSuccess) {
          if (response.data.maelstrom !== true) {
            setToast({
              status: "success",
              message: "Successfully updated provider configuration.",
            });
          }
        }

        invalidateProviderConfiguration(groupId);

        // When an integration changes, we want to invalidate this list query.
        // Some features in CMW are only enabled when a certain type of integration
        // is enabled or when a feature of an integration is enabled. Therefore,
        // when any setting of integration changes, we want to refresh this list
        // so that can update the availability of CMW features accordingly.
        //
        // For example, at the time of writing, this is sometimes used to calculate
        // whether a user can see the My Access tab.
        invalidateProviderConfigurationsQuery();

        // Refresh the integrations list after an integration is enabled or disabled
        if (params.enabled !== undefined) {
          queryClient.invalidateQueries(["providers", { groupId }]);
        }

        return response.data;
      } catch (err) {
        const message = getErrorMessage(err);
        const funnelError =
          "API Key and Community ID are required to enable Funnel Integration";
        const knockError =
          "Property settings are required to add Knock Integration";
        const lassoError = "Missing required credential token";
        const settingsLink = "integrationSettings";
        const credentialsLink = "credentials";

        const getErrorModal = async ({
          providerName,
          errorMessage,
          integrationLink,
        }: {
          providerName: string;
          errorMessage: React.ReactNode;
          integrationLink: keyof typeof settingsLinks.integrations;
        }) => {
          const confirmed = await confirm({
            title: `Error Enabling ${providerName}`,
            // Casting to string to support rendering React components
            description: errorMessage as unknown as string,
            confirmText:
              integrationLink === settingsLink
                ? "Go To Settings"
                : "Go To Credentials",
            confirmType: "primary",
          });

          const providerSlug: string = await getProvider(providerId).then(
            (data: { provider: Provider }) => {
              return data.provider.provider_slug;
            }
          );

          if (confirmed) {
            history.push(
              settingsLinks.integrations[integrationLink]({
                groupId,
                providerSlug,
              })
            );
          }
        };

        switch (message) {
          case knockError: {
            return getErrorModal({
              providerName: ProviderName.Knock,
              errorMessage: knockError,
              integrationLink: settingsLink,
            });
          }

          case lassoError: {
            return getErrorModal({
              providerName: ProviderName.Lasso,
              errorMessage: lassoError,
              integrationLink: credentialsLink,
            });
          }

          case funnelError: {
            const { configuration: providerConfiguration } =
              await queryClient.fetchQuery<{
                configuration: ProviderConfiguration;
              }>(["provider-configuration", { groupId, providerId }]);

            const missingField = !providerConfiguration?.provider_credentials
              ?.api_key
              ? "API Key"
              : "Community ID";

            return getErrorModal({
              providerName: ProviderName.Funnel,
              errorMessage: (
                <Typography>
                  {missingField === "API Key" ? "An " : "A "}
                  <Typography font="bold">{missingField}</Typography>
                  {` is required to enable the ${ProviderName.Funnel} integration.`}
                </Typography>
              ),
              integrationLink:
                missingField === "API Key" ? credentialsLink : settingsLink,
            });
          }

          default: {
            if (toastOnError) {
              setToast({
                status: "error",
                message: `Failed to update provider configuration. ${message}`,
              });
            } else {
              // If we don't want to show a toast message here then throw the error
              // so that it can be handled elsewhere. We reset err.message because
              // we already did the work to caculate a more appropriate message.
              err.message = message;
              throw err;
            }
          }
        }
      }
    },
    options
  );
};

/**
 * Retrieve the full list of card-banks data for all enabled integrations
 * in the given group.
 */
export const useGetCardBanks = createAxiosQuery(
  "card-banks",
  async ({ groupId }: { groupId: Id }) => {
    const { data } = await instance().get<CardBank[]>(
      `/groups/${groupId}/settings/integrations/card-banks`
    );

    return data;
  },
  {
    placeholderData: [],
  }
);

/**
 * Retrieve the display name of the given card format, if one exists.
 *
 * This returns a function so that it can be called on-demand instead
 * of needing to be called as a hook. This makes it easier to use when
 * displaying a list of information, such as in a table.
 */
export const useGetCardBank = (groupId: Id) => {
  const { data: cardBanks } = useGetCardBanks({ groupId });

  return (cardFormat: string) => {
    return (
      (cardBanks || []).find((cardBank) => cardBank.value === cardFormat)
        ?.label || ""
    );
  };
};
