import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { StyleSheet } from "react-native";

import { Link, useTheme } from "@smartrent/ui";
import { useWindowDimensionsQuery } from "@smartrent/hooks";
import { ArrowLeft } from "@smartrent/icons";

import { useSearchBox } from "@/react/hooks/useSearchBox";
import { useGetUnitQuery } from "@/react/queries/mgmt-api/units/units";
import { useGroup } from "@/react/queries/mgmt-api/group";

import * as links from "@/lib/links";

import {
  GroupHeader,
  SidebarLegacy,
  useUnitNavItems,
} from "@/react/common/page_elements";

export const UnitSidebar = () => {
  const { colors } = useTheme();
  const { isBelowMedium } = useWindowDimensionsQuery({
    isBelowMedium: ({ width }) => width <= 768,
  });

  const params: Record<string, any> = useParams();
  const { data: unit } = useGetUnitQuery(params?.unitId);

  const groupId = params?.groupId || unit?.group_id;

  const { data: group } = useGroup(groupId);

  const header = useMemo(
    () => <GroupHeader group={group} unit={unit} theme="light" />,
    [group, unit]
  );

  const navItems = useUnitNavItems();

  useSearchBox(groupId);

  return (
    <SidebarLegacy
      color={isBelowMedium ? null : "primary"}
      header={header}
      navItems={navItems}
      backButton={
        isBelowMedium || !groupId ? null : (
          <Link
            to={links.group.units.list({ groupId })}
            style={[styles.backButton, { backgroundColor: colors.secondary }]}
          >
            <ArrowLeft />
            All Units
          </Link>
        )
      }
    />
  );
};

const styles = StyleSheet.create({
  backButton: {
    display: "flex",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingHorizontal: 16,
    color: "white",
    borderRadius: 3,
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: 21,
  },
});
